import * as React from 'react';
import {
	Box,
	Checkbox,
	Label,
	LabelInputText,
	popupController,
	RsFormControl,
	RsFormGroup,
	RsValidator,
	RsValidatorEnum
} from '@redskytech/framework/ui';
import { TestKey } from '../FlightTestSection';
import ServiceFactory from '../../../../services/serviceFactory';
import { useRecoilValue } from 'recoil';
import { TestResult } from '../../../../services/testFixture/ITestFixtureService';
import globalState from '../../../../state/globalState';
import { useEffect, useState } from 'react';
import { IRsFormControl } from '@redskytech/framework/ui/form/FormControl';

import WarningPopup, { WarningPopupProps } from '../../../../popups/warningPopup/WarningPopup';
import EvaluationItem, {
	EvaluationGroup,
	EvaluationOptions
} from '../../../../components/evaluationItem/EvaluationItem';

interface VehicleRadioRangeTestingProps {
	alignedAbsRssiBestWorst: [number, number];
	sideAbsRssiBestWorst: [number, number];
	frontToBackRssiDeltaMax: number;
	leftToRightRssiDeltaMin: number;
	throughputMinMax: [number, number];
	absoluteRssiBestThreshold: number;
}

type ReviewRadioEvaluationGroup = EvaluationGroup & {
	radioRangeQualityAccepted: EvaluationOptions | undefined;
};

type EvaluationKey = keyof ReviewRadioEvaluationGroup;

enum FormKeys {
	RSSI_NOSE_OUT_VEH_1 = 'rssiFromVehNoseOut1',
	RSSI_NOSE_OUT_VEH_2 = 'rssiFromVehNoseOut2',
	RSSI_NOSE_OUT_FCU_1 = 'rssiFromFcuNoseOut1',
	RSSI_NOSE_OUT_FCU_2 = 'rssiFromFcuNoseOut2',
	THROUGHPUT_NOSE_OUT = 'throughputFromFcuNoseOut',
	NOSE_OUT_VIDEO_QUALITY_PITCH = 'noseOutVideoQualityPitch',
	NOSE_OUT_VIDEO_QUALITY_ROLL = 'noseOutVideoQualityRoll',

	RSSI_NOSE_IN_VEH_1 = 'rssiFromVehNoseIn1',
	RSSI_NOSE_IN_VEH_2 = 'rssiFromVehNoseIn2',
	RSSI_NOSE_IN_FCU_1 = 'rssiFromFcuNoseIn1',
	RSSI_NOSE_IN_FCU_2 = 'rssiFromFcuNoseIn2',
	THROUGHPUT_NOSE_IN = 'throughputFromFcuNoseIn',
	NOSE_IN_VIDEO_QUALITY_PITCH = 'noseInVideoQualityPitch',
	NOSE_IN_VIDEO_QUALITY_ROLL = 'noseInVideoQualityRoll',

	RSSI_NOSE_LEFT_VEH_1 = 'rssiFromVehNoseLeft1',
	RSSI_NOSE_LEFT_VEH_2 = 'rssiFromVehNoseLeft2',
	RSSI_NOSE_LEFT_FCU_1 = 'rssiFromFcuNoseLeft1',
	RSSI_NOSE_LEFT_FCU_2 = 'rssiFromFcuNoseLeft2',
	THROUGHPUT_NOSE_LEFT = 'throughputFromFcuNoseLeft',
	NOSE_LEFT_VIDEO_QUALITY_PITCH = 'noseLeftVideoQualityPitch',
	NOSE_LEFT_VIDEO_QUALITY_ROLL = 'noseLeftVideoQualityRoll',

	RSSI_NOSE_RIGHT_VEH_1 = 'rssiFromVehNoseRight1',
	RSSI_NOSE_RIGHT_VEH_2 = 'rssiFromVehNoseRight2',
	RSSI_NOSE_RIGHT_FCU_1 = 'rssiFromFcuNoseRight1',
	RSSI_NOSE_RIGHT_FCU_2 = 'rssiFromFcuNoseRight2',
	THROUGHPUT_NOSE_RIGHT = 'throughputFromFcuNoseRight',
	NOSE_RIGHT_VIDEO_QUALITY_PITCH = 'noseRightVideoQualityPitch',
	NOSE_RIGHT_VIDEO_QUALITY_ROLL = 'noseRightVideoQualityRoll',
	ACCEPT_RADIO_RANGE_QUALITY = 'acceptRadioRangeQuality'
}

const VehicleRadioRangeTesting: React.FC<VehicleRadioRangeTestingProps> = (props) => {
	const TEST_NAME: TestKey = 'radioRangeTesting';
	const testFixtureService = ServiceFactory.get('TestFixtureService');
	const testResults = useRecoilValue<TestResult[]>(globalState.testResults);
	let res = testResults.find((prevRes) => prevRes.testName === TEST_NAME);
	const [popupState, setPopupState] = useState({ isVisible: false, message: '', title: '' });
	const [evaluations, setEvaluations] = useState<ReviewRadioEvaluationGroup>({
		radioRangeQualityAccepted: res ? res.data['radioRangeQualityAccepted'] : undefined
	});
	const [formGroup, setFormGroup] = useState<RsFormGroup>(
		new RsFormGroup([
			new RsFormControl<string>(FormKeys.RSSI_NOSE_OUT_FCU_1, res ? res.data[FormKeys.RSSI_NOSE_OUT_FCU_1] : '', [
				new RsValidator(RsValidatorEnum.CUSTOM, '', (control) => {
					return control.value !== '' ? true : false;
				})
			]),
			new RsFormControl<string>(FormKeys.RSSI_NOSE_OUT_FCU_2, res ? res.data[FormKeys.RSSI_NOSE_OUT_FCU_2] : '', [
				new RsValidator(RsValidatorEnum.CUSTOM, '', (control) => {
					return control.value !== '' ? true : false;
				})
			]),
			new RsFormControl<string>(FormKeys.RSSI_NOSE_OUT_VEH_1, res ? res.data[FormKeys.RSSI_NOSE_OUT_VEH_1] : '', [
				new RsValidator(RsValidatorEnum.CUSTOM, '', (control): boolean => {
					const currentValue = testFixtureService.controlValueToNumber(control);
					const neighborValue = testFixtureService.controlValueToNumber(
						formGroup.get(FormKeys.RSSI_NOSE_OUT_VEH_2)
					);
					if (currentValue === undefined || neighborValue === undefined) return false;
					if (Math.abs(currentValue) < props.absoluteRssiBestThreshold) {
						return false;
					}
					return testFixtureService.validateAbsResultRssiInRangeWithRelative(
						currentValue,
						props.alignedAbsRssiBestWorst[0],
						props.alignedAbsRssiBestWorst[1],
						neighborValue,
						props.frontToBackRssiDeltaMax,
						true
					);
				})
			]),
			new RsFormControl<string>(FormKeys.RSSI_NOSE_OUT_VEH_2, res ? res.data[FormKeys.RSSI_NOSE_OUT_VEH_2] : '', [
				new RsValidator(RsValidatorEnum.CUSTOM, '', (control): boolean => {
					const currentValue = testFixtureService.controlValueToNumber(control);
					const neighborValue = testFixtureService.controlValueToNumber(
						formGroup.get(FormKeys.RSSI_NOSE_OUT_VEH_1)
					);
					if (currentValue === undefined || neighborValue === undefined) return false;
					if (Math.abs(currentValue) < props.absoluteRssiBestThreshold) {
						return false;
					}
					return testFixtureService.validateAbsResultRssiInRangeWithRelative(
						currentValue,
						props.alignedAbsRssiBestWorst[0],
						props.alignedAbsRssiBestWorst[1],
						neighborValue,
						props.frontToBackRssiDeltaMax,
						true
					);
				})
			]),
			new RsFormControl<string>(FormKeys.THROUGHPUT_NOSE_OUT, res ? res.data[FormKeys.THROUGHPUT_NOSE_OUT] : '', [
				new RsValidator(RsValidatorEnum.CUSTOM, '', (control) => {
					return control.value !== '' ? true : false;
				})
			]),

			new RsFormControl<string>(
				FormKeys.RSSI_NOSE_LEFT_FCU_1,
				res ? res.data[FormKeys.RSSI_NOSE_LEFT_FCU_1] : '',
				[
					new RsValidator(RsValidatorEnum.CUSTOM, '', (control) => {
						return control.value !== '' ? true : false;
					})
				]
			),
			new RsFormControl<string>(
				FormKeys.RSSI_NOSE_LEFT_FCU_2,
				res ? res.data[FormKeys.RSSI_NOSE_LEFT_FCU_2] : '',
				[
					new RsValidator(RsValidatorEnum.CUSTOM, '', (control) => {
						return control.value !== '' ? true : false;
					})
				]
			),
			new RsFormControl<string>(
				FormKeys.RSSI_NOSE_LEFT_VEH_1,
				res ? res.data[FormKeys.RSSI_NOSE_LEFT_VEH_1] : '',
				[
					new RsValidator(RsValidatorEnum.CUSTOM, '', (control): boolean => {
						const currentValue = testFixtureService.controlValueToNumber(control);
						const neighborValue = testFixtureService.controlValueToNumber(
							formGroup.get(FormKeys.RSSI_NOSE_LEFT_VEH_2)
						);
						if (currentValue === undefined || neighborValue === undefined) return false;
						if (Math.abs(currentValue) < props.absoluteRssiBestThreshold) {
							return false;
						}

						return testFixtureService.validateAbsResultRssiInRangeWithRelative(
							currentValue,
							props.sideAbsRssiBestWorst[0],
							props.sideAbsRssiBestWorst[1],
							neighborValue,
							props.leftToRightRssiDeltaMin,
							false
						);
					})
				]
			),
			new RsFormControl<string>(
				FormKeys.RSSI_NOSE_LEFT_VEH_2,
				res ? res.data[FormKeys.RSSI_NOSE_LEFT_VEH_2] : '',
				[
					new RsValidator(RsValidatorEnum.CUSTOM, '', (control): boolean => {
						const currentValue = testFixtureService.controlValueToNumber(control);
						const neighborValue = testFixtureService.controlValueToNumber(
							formGroup.get(FormKeys.RSSI_NOSE_LEFT_VEH_1)
						);
						if (currentValue === undefined || neighborValue === undefined) return false;
						if (Math.abs(currentValue) < props.absoluteRssiBestThreshold) {
							return false;
						}

						return testFixtureService.validateAbsResultRssiInRangeWithRelative(
							currentValue,
							props.sideAbsRssiBestWorst[0],
							props.sideAbsRssiBestWorst[1],
							neighborValue,
							props.leftToRightRssiDeltaMin,
							false
						);
					})
				]
			),
			new RsFormControl<string>(
				FormKeys.THROUGHPUT_NOSE_LEFT,
				res ? res.data[FormKeys.THROUGHPUT_NOSE_LEFT] : '',
				[
					new RsValidator(RsValidatorEnum.CUSTOM, '', (control) => {
						return control.value !== '' ? true : false;
					})
				]
			),

			new RsFormControl<string>(FormKeys.RSSI_NOSE_IN_FCU_1, res ? res.data[FormKeys.RSSI_NOSE_IN_FCU_1] : '', [
				new RsValidator(RsValidatorEnum.CUSTOM, '', (control) => {
					return control.value !== '' ? true : false;
				})
			]),
			new RsFormControl<string>(FormKeys.RSSI_NOSE_IN_FCU_2, res ? res.data[FormKeys.RSSI_NOSE_IN_FCU_2] : '', [
				new RsValidator(RsValidatorEnum.CUSTOM, '', (control) => {
					return control.value !== '' ? true : false;
				})
			]),
			new RsFormControl<string>(FormKeys.RSSI_NOSE_IN_VEH_1, res ? res.data[FormKeys.RSSI_NOSE_IN_VEH_1] : '', [
				new RsValidator(RsValidatorEnum.CUSTOM, '', (control) => {
					const currentValue = testFixtureService.controlValueToNumber(control);
					const neighborValue = testFixtureService.controlValueToNumber(
						formGroup.get(FormKeys.RSSI_NOSE_IN_VEH_2)
					);
					if (currentValue === undefined || neighborValue === undefined) return false;
					if (Math.abs(currentValue) < props.absoluteRssiBestThreshold) {
						return false;
					}

					return testFixtureService.validateAbsResultRssiInRangeWithRelative(
						currentValue,
						props.alignedAbsRssiBestWorst[0],
						props.alignedAbsRssiBestWorst[1],
						neighborValue,
						props.frontToBackRssiDeltaMax,
						true
					);
				})
			]),
			new RsFormControl<string>(FormKeys.RSSI_NOSE_IN_VEH_2, res ? res.data[FormKeys.RSSI_NOSE_IN_VEH_2] : '', [
				new RsValidator(RsValidatorEnum.CUSTOM, '', (control) => {
					const currentValue = testFixtureService.controlValueToNumber(control);
					const neighborValue = testFixtureService.controlValueToNumber(
						formGroup.get(FormKeys.RSSI_NOSE_IN_VEH_1)
					);
					if (currentValue === undefined || neighborValue === undefined) return false;
					if (Math.abs(currentValue) < props.absoluteRssiBestThreshold) {
						return false;
					}

					return testFixtureService.validateAbsResultRssiInRangeWithRelative(
						currentValue,
						props.alignedAbsRssiBestWorst[0],
						props.alignedAbsRssiBestWorst[1],
						neighborValue,
						props.frontToBackRssiDeltaMax,
						true
					);
				})
			]),
			new RsFormControl<string>(FormKeys.THROUGHPUT_NOSE_IN, res ? res.data[FormKeys.THROUGHPUT_NOSE_IN] : '', [
				new RsValidator(RsValidatorEnum.CUSTOM, '', (control) => {
					return control.value !== '' ? true : false;
				})
			]),

			new RsFormControl<string>(
				FormKeys.RSSI_NOSE_RIGHT_FCU_1,
				res ? res.data[FormKeys.RSSI_NOSE_RIGHT_FCU_1] : '',
				[
					new RsValidator(RsValidatorEnum.CUSTOM, '', (control) => {
						return control.value !== '' ? true : false;
					})
				]
			),
			new RsFormControl<string>(
				FormKeys.RSSI_NOSE_RIGHT_FCU_2,
				res ? res.data[FormKeys.RSSI_NOSE_RIGHT_FCU_2] : '',
				[
					new RsValidator(RsValidatorEnum.CUSTOM, '', (control) => {
						return control.value !== '' ? true : false;
					})
				]
			),
			new RsFormControl<string>(
				FormKeys.RSSI_NOSE_RIGHT_VEH_1,
				res ? res.data[FormKeys.RSSI_NOSE_RIGHT_VEH_1] : '',
				[
					new RsValidator(RsValidatorEnum.CUSTOM, '', (control) => {
						const currentValue = testFixtureService.controlValueToNumber(control);
						const neighborValue = testFixtureService.controlValueToNumber(
							formGroup.get(FormKeys.RSSI_NOSE_RIGHT_VEH_2)
						);
						if (currentValue === undefined || neighborValue === undefined) return false;
						if (Math.abs(currentValue) < props.absoluteRssiBestThreshold) {
							return false;
						}

						return testFixtureService.validateAbsResultRssiInRangeWithRelative(
							currentValue,
							props.sideAbsRssiBestWorst[0],
							props.sideAbsRssiBestWorst[1],
							neighborValue,
							props.leftToRightRssiDeltaMin,
							false
						);
					})
				]
			),
			new RsFormControl<string>(
				FormKeys.RSSI_NOSE_RIGHT_VEH_2,
				res ? res.data[FormKeys.RSSI_NOSE_RIGHT_VEH_2] : '',
				[
					new RsValidator(RsValidatorEnum.CUSTOM, '', (control) => {
						const currentValue = testFixtureService.controlValueToNumber(control);
						const neighborValue = testFixtureService.controlValueToNumber(
							formGroup.get(FormKeys.RSSI_NOSE_RIGHT_VEH_1)
						);
						if (currentValue === undefined || neighborValue === undefined) return false;
						if (Math.abs(currentValue) < props.absoluteRssiBestThreshold) {
							return false;
						}

						return testFixtureService.validateAbsResultRssiInRangeWithRelative(
							currentValue,
							props.sideAbsRssiBestWorst[0],
							props.sideAbsRssiBestWorst[1],
							neighborValue,
							props.leftToRightRssiDeltaMin,
							false
						);
					})
				]
			),
			new RsFormControl<string>(
				FormKeys.THROUGHPUT_NOSE_RIGHT,
				res ? res.data[FormKeys.THROUGHPUT_NOSE_RIGHT] : '',
				[
					new RsValidator(RsValidatorEnum.CUSTOM, '', (control) => {
						return control.value !== '' ? true : false;
					})
				]
			),

			new RsFormControl<boolean>(
				FormKeys.NOSE_OUT_VIDEO_QUALITY_PITCH,
				res ? res.data[FormKeys.NOSE_OUT_VIDEO_QUALITY_PITCH] : false,
				[
					new RsValidator(RsValidatorEnum.CUSTOM, '', (control) => {
						return !!control.value;
					})
				]
			),
			new RsFormControl<boolean>(
				FormKeys.NOSE_OUT_VIDEO_QUALITY_ROLL,
				res ? res.data[FormKeys.NOSE_OUT_VIDEO_QUALITY_ROLL] : false,
				[
					new RsValidator(RsValidatorEnum.CUSTOM, '', (control) => {
						return !!control.value;
					})
				]
			),

			new RsFormControl<boolean>(
				FormKeys.NOSE_LEFT_VIDEO_QUALITY_PITCH,
				res ? res.data[FormKeys.NOSE_LEFT_VIDEO_QUALITY_PITCH] : false,
				[
					new RsValidator(RsValidatorEnum.CUSTOM, '', (control) => {
						return !!control.value;
					})
				]
			),
			new RsFormControl<boolean>(
				FormKeys.NOSE_LEFT_VIDEO_QUALITY_ROLL,
				res ? res.data[FormKeys.NOSE_LEFT_VIDEO_QUALITY_ROLL] : false,
				[
					new RsValidator(RsValidatorEnum.CUSTOM, '', (control) => {
						return !!control.value;
					})
				]
			),

			new RsFormControl<boolean>(
				FormKeys.NOSE_IN_VIDEO_QUALITY_PITCH,
				res ? res.data[FormKeys.NOSE_IN_VIDEO_QUALITY_PITCH] : false,
				[
					new RsValidator(RsValidatorEnum.CUSTOM, '', (control) => {
						return !!control.value;
					})
				]
			),
			new RsFormControl<boolean>(
				FormKeys.NOSE_IN_VIDEO_QUALITY_ROLL,
				res ? res.data[FormKeys.NOSE_IN_VIDEO_QUALITY_ROLL] : false,
				[
					new RsValidator(RsValidatorEnum.CUSTOM, '', (control) => {
						return !!control.value;
					})
				]
			),

			new RsFormControl<boolean>(
				FormKeys.NOSE_RIGHT_VIDEO_QUALITY_PITCH,
				res ? res.data[FormKeys.NOSE_RIGHT_VIDEO_QUALITY_PITCH] : false,
				[
					new RsValidator(RsValidatorEnum.CUSTOM, '', (control) => {
						return !!control.value;
					})
				]
			),
			new RsFormControl<boolean>(
				FormKeys.NOSE_RIGHT_VIDEO_QUALITY_ROLL,
				res ? res.data[FormKeys.NOSE_RIGHT_VIDEO_QUALITY_ROLL] : false,
				[
					new RsValidator(RsValidatorEnum.CUSTOM, '', (control) => {
						return !!control.value;
					})
				]
			),
			new RsFormControl<boolean>(
				FormKeys.ACCEPT_RADIO_RANGE_QUALITY,
				res ? res.data[FormKeys.ACCEPT_RADIO_RANGE_QUALITY] : false,
				[
					new RsValidator(RsValidatorEnum.CUSTOM, '', (control) => {
						return !!control.value;
					})
				]
			)
		])
	);

	useEffect(() => {
		if (!popupState.isVisible) return;
		let popupId = popupController.open<WarningPopupProps>(WarningPopup, {
			title: popupState.title,
			message: popupState.message,
			confirmButtonText: 'Continue',
			onConfirm: () => {
				setPopupState((prev) => {
					return { isVisible: false, message: '', title: '' };
				});
			}
		});

		return () => {
			if (popupId) {
				popupController.closeById(popupId);
			}
		};
	}, [popupState]);

	function checkRssiValue(rssiValue: number, rssiThresholdLow: number, rssiThresholdHigh: number) {
		const rssiValueAbs = Math.abs(rssiValue);
		const rssiThresholdLowAbs = Math.abs(props.absoluteRssiBestThreshold);

		if (rssiValueAbs < rssiThresholdLowAbs) {
			setPopupState((prev) => {
				return {
					isVisible: true,
					message: `RSSI value is very low, please inspect test setup. Received ${rssiValue}, expected value between ${rssiThresholdLowAbs} and ${rssiThresholdHigh}`,
					title: 'Comm Fault Warning'
				};
			});
		}
	}

	function handleUpdateControl(control: RsFormControl<IRsFormControl>) {
		setFormGroup(formGroup.clone().update(control));
		testFixtureService.validateFlightTest(formGroup, TEST_NAME, false, FormKeys.ACCEPT_RADIO_RANGE_QUALITY);
	}
	function handleEvaluationClick(evaluation: EvaluationOptions, key: EvaluationKey) {
		if (key === 'radioRangeQualityAccepted') {
			let control = formGroup.get(FormKeys.ACCEPT_RADIO_RANGE_QUALITY);
			control.value = evaluation === 'PASS';
			setFormGroup(formGroup.clone().update(control));
		}

		setEvaluations((prevState) => {
			let updatedState = { ...prevState };
			updatedState[key] = evaluation;
			return updatedState;
		});

		testFixtureService.validateFlightTest(formGroup, TEST_NAME, false, FormKeys.ACCEPT_RADIO_RANGE_QUALITY);
	}

	function renderTestItem(
		label: string,
		formKeyVehRssi1: FormKeys,
		formKeyVehRssi2: FormKeys,
		formKeyFcuRssi1: FormKeys,
		formKeyFcuRssi2: FormKeys,
		formKeyThroughput: FormKeys,
		formKeyVideoQualityPitch: FormKeys,
		formKeyVideoQualityRoll: FormKeys
	) {
		let thresholds =
			label.includes('Out') || label.includes('In') ? props.alignedAbsRssiBestWorst : props.sideAbsRssiBestWorst;

		return (
			<Box key={label} marginBottom={16}>
				<Label weight={'bold'} variant={'body1'}>{`${label}`}</Label>
				<Box display={'grid'} gridTemplateColumns={'1fr 1fr'} gap={16}>
					<LabelInputText
						labelTitle={'RSSI 1 from Vehicle'}
						inputMode={'text'}
						type={'text'}
						control={formGroup.get(formKeyVehRssi1)}
						updateControl={handleUpdateControl}
						onBlur={(event) => {
							checkRssiValue(parseFloat(event.target.value), thresholds[0], thresholds[1]);
						}}
					/>
					<LabelInputText
						labelTitle={'RSSI 2 from Vehicle'}
						inputMode={'text'}
						type={'text'}
						control={formGroup.get(formKeyVehRssi2)}
						updateControl={handleUpdateControl}
						onBlur={(event) => {
							checkRssiValue(parseFloat(event.target.value), thresholds[0], thresholds[1]);
						}}
					/>
					<LabelInputText
						labelTitle={'RSSI 1 from attenuated FCU'}
						inputMode={'text'}
						type={'text'}
						control={formGroup.get(formKeyFcuRssi1)}
						updateControl={handleUpdateControl}
						onBlur={(event) => {
							checkRssiValue(parseFloat(event.target.value), thresholds[0], thresholds[1]);
						}}
					/>
					<LabelInputText
						labelTitle={'RSSI 2 from attenuated FCU'}
						inputMode={'text'}
						type={'text'}
						control={formGroup.get(formKeyFcuRssi2)}
						updateControl={handleUpdateControl}
						onBlur={(event) => {
							checkRssiValue(parseFloat(event.target.value), thresholds[0], thresholds[1]);
						}}
					/>
					<LabelInputText
						labelTitle={'Throughput from attenuated FCU'}
						inputMode={'text'}
						type={'text'}
						control={formGroup.get(formKeyThroughput)}
						updateControl={handleUpdateControl}
					/>
				</Box>
				<Box></Box>
				<Box marginTop={8} display={'grid'} gridTemplateColumns={'1fr 1fr'}>
					<Checkbox
						labelText={'No snowy video on pitch maneuver'}
						look={'containedPrimary'}
						control={formGroup.get(formKeyVideoQualityPitch)}
						updateControl={handleUpdateControl}
					/>
					<Checkbox
						labelText={'No snowy video on roll maneuver'}
						look={'containedPrimary'}
						control={formGroup.get(formKeyVideoQualityRoll)}
						updateControl={handleUpdateControl}
					/>
				</Box>
			</Box>
		);
	}

	return (
		<Box className={'rsRadioRangeTesting'} key={TEST_NAME}>
			{[
				renderTestItem(
					'Nose Out',
					FormKeys.RSSI_NOSE_OUT_VEH_1,
					FormKeys.RSSI_NOSE_OUT_VEH_2,
					FormKeys.RSSI_NOSE_OUT_FCU_1,
					FormKeys.RSSI_NOSE_OUT_FCU_2,
					FormKeys.THROUGHPUT_NOSE_OUT,
					FormKeys.NOSE_OUT_VIDEO_QUALITY_PITCH,
					FormKeys.NOSE_OUT_VIDEO_QUALITY_ROLL
				),
				renderTestItem(
					'Nose Left',
					FormKeys.RSSI_NOSE_LEFT_VEH_1,
					FormKeys.RSSI_NOSE_LEFT_VEH_2,
					FormKeys.RSSI_NOSE_LEFT_FCU_1,
					FormKeys.RSSI_NOSE_LEFT_FCU_2,
					FormKeys.THROUGHPUT_NOSE_LEFT,
					FormKeys.NOSE_LEFT_VIDEO_QUALITY_PITCH,
					FormKeys.NOSE_LEFT_VIDEO_QUALITY_ROLL
				),
				renderTestItem(
					'Nose In',
					FormKeys.RSSI_NOSE_IN_VEH_1,
					FormKeys.RSSI_NOSE_IN_VEH_2,
					FormKeys.RSSI_NOSE_IN_FCU_1,
					FormKeys.RSSI_NOSE_IN_FCU_2,
					FormKeys.THROUGHPUT_NOSE_IN,
					FormKeys.NOSE_IN_VIDEO_QUALITY_PITCH,
					FormKeys.NOSE_IN_VIDEO_QUALITY_ROLL
				),
				renderTestItem(
					'Nose Right',
					FormKeys.RSSI_NOSE_RIGHT_VEH_1,
					FormKeys.RSSI_NOSE_RIGHT_VEH_2,
					FormKeys.RSSI_NOSE_RIGHT_FCU_1,
					FormKeys.RSSI_NOSE_RIGHT_FCU_2,
					FormKeys.THROUGHPUT_NOSE_RIGHT,
					FormKeys.NOSE_RIGHT_VIDEO_QUALITY_PITCH,
					FormKeys.NOSE_RIGHT_VIDEO_QUALITY_ROLL
				)
			]}
			<EvaluationItem
				label={'Radio Range Testing reviewed and approved'}
				onEvaluationClick={(evaluation) => {
					handleEvaluationClick(evaluation, 'radioRangeQualityAccepted');
				}}
				evaluation={evaluations.radioRangeQualityAccepted}
			/>
		</Box>
	);
};
export default VehicleRadioRangeTesting;
