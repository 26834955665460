import { Box, Img, Label, rsToastify } from '@redskytech/framework/ui';
import { ObjectUtils } from '@redskytech/framework/utils';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { ApiRequestV1 } from '../../generated/apiRequests';
import { SummaryData } from '../../pages/assemblyDetailsPage/testSummaryTable/TestSummaryTable';
import { fcuTestKeys } from '../../pages/flightTestPage/sections/FcuFlightTestList';
import { livePayloadTestKeys } from '../../pages/flightTestPage/sections/LivePayloadFlightTestList';
import { trainerPayloadTestKeys } from '../../pages/flightTestPage/sections/TrainerPayloadFlightTestList';
import { vehicleTestKeys } from '../../pages/flightTestPage/sections/VehicleFlightTestList';
import { magTestKeys } from '../../pages/magTestPage/MagTestSection';
import {
	fcuPartNumbers,
	livePayloadPartNumbers,
	trainerPayloadPartNumbers
} from '../../services/assembly/assembly.data';
import { HardwareIdDecoded } from '../../services/assembly/AssemblyService';
import { KitGroupType } from '../../services/kit/IKitService';
import serviceFactory from '../../services/serviceFactory';
import { optionalTestFixtures, TestFixtureType } from '../../services/testFixture/ITestFixtureService';
import { testFixtureTestMap } from '../../services/testFixture/testFixture.data';
import globalState from '../../state/globalState';
import { StringUtils, WebUtils } from '../../utils/utils';
import StatusChip from '../statusChip/StatusChip';
import './KitCheckoutPdf.scss';
import KitCheckoutTable from './kitCheckoutTable/KitCheckoutTable';
import { getAllTestDataByPartId } from '../../utils/generateAuditList';
import LoadingSpinner from '../loadingSpinner/LoadingSpinner';

export interface KitCheckoutPdfProps {
	serialNumber: string;
	salesOrder: string;
	kitCheckoutGroup?: { group: KitGroupType; idList: HardwareIdDecoded[] }[];
	kitCheckoutPdfRef: React.RefObject<HTMLDivElement>;
}

const KitCheckoutPdf: React.FC<KitCheckoutPdfProps> = (props) => {
	const assemblyService = serviceFactory.get('AssemblyService');
	const user = useRecoilValue<Api.V1.User.Me.Get.Res | undefined>(globalState.user);
	const [groupedSummaryData, setGroupedSummaryData] = useState<any[]>([]);
	const expectedTestNamesRef = useRef<string[]>([]);

	useEffect(() => {
		(async () => {
			const summaries: React.ReactNode[] = [];
			if (!props.kitCheckoutGroup) return;
			for (let group of props.kitCheckoutGroup) {
				const groupTypeOptions: Partial<KitGroupType>[] = ['FCU_ANTENNA', 'PAYLOAD', 'VEHICLE'];

				if (groupTypeOptions.includes(group.group)) {
					for (let item of group.idList) {
						if (!item.partId) continue;

						const { familyTree } = await ApiRequestV1.getPartFamilyTree({ partId: item.partId });

						await processFamilyTreeItem(familyTree, summaries, group.group);
					}
				}
			}
			setGroupedSummaryData(summaries);
		})();
	}, [props.kitCheckoutGroup]);

	async function processFamilyTreeItem(
		item: CustomTypes.PartFamilyTreeResponse['familyTree'],
		summaries: any[],
		groupName: string
	) {
		if (!item) return;

		const test = await getTest(item.partNumber);
		let summaryData = (await getGroupedSummaryData(item.id, test)) || [];
		const missingTest = await getAllTestDataByPartId(item.id);

		let partLabel = assemblyService.getLabelFromPartNumber(item.partNumber);
		let missingTestGroup = missingTest.find((item) => item.group === partLabel);
		if (missingTestGroup) {
			for (let missingTestItem of missingTestGroup.tests) {
				if (missingTestItem.testName === 'FAIL') continue;
				for (let testName of Object.keys(test) as (keyof typeof test)[]) {
					if (test[testName].includes(StringUtils.toCamelCase(missingTestItem.testName))) {
						let index = summaryData.findIndex((summaryDataItem) => summaryDataItem.group === testName);
						if (index < 0) {
							summaryData.push({
								group: testName.toString(),
								tests: [missingTestItem]
							});
							continue;
						}

						if (!summaryData[index].tests) summaryData[index].tests = [missingTestItem];
						else summaryData[index].tests.push(missingTestItem);
						break;
					}
				}
			}
		}

		if (summaryData.length) {
			summaries.push({
				name: `${item.partNumber.replace(/-/g, '_')} - ${assemblyService.getLabelFromPartNumber(
					item.partNumber
				)}, ${item.id}`,
				testGroup: [...summaryData]
			});
		}

		for (const child of item.children) {
			await processFamilyTreeItem(child, summaries, groupName);
		}
	}

	async function getTest(partNumber: string): Promise<{ [key: string]: string[] }> {
		const assemblyService = serviceFactory.get('AssemblyService');
		const discoveredPartAssemblyType = assemblyService.getPartAssemblyTypeFromPartNumber(partNumber);

		let testNames: string[] = [];
		let testGroups: any = {};
		if (discoveredPartAssemblyType && testFixtureTestMap.hasOwnProperty(discoveredPartAssemblyType)) {
			let fixture: TestFixtureType;
			for (fixture in testFixtureTestMap[discoveredPartAssemblyType]) {
				if (
					!testFixtureTestMap[discoveredPartAssemblyType].hasOwnProperty(fixture) ||
					optionalTestFixtures.includes(fixture)
				)
					continue;

				testGroups[testFixtureTestMap[discoveredPartAssemblyType][fixture]!.name] = [];
				for (let test of testFixtureTestMap[discoveredPartAssemblyType][fixture]!.tests) {
					testGroups[testFixtureTestMap[discoveredPartAssemblyType][fixture]!.name].push(test.testName);
					testNames.push(test.testName);
				}
			}
		}

		// add payload appropriate flight tests
		if (trainerPayloadPartNumbers.includes(partNumber)) {
			testGroups['Trainer Payload Tests'] = [];
			trainerPayloadTestKeys.forEach((key) => {
				testGroups['Trainer Payload Tests'].push(key);
				testNames.push(key);
			});
		}

		// add payload appropriate flight tests
		if (livePayloadPartNumbers.includes(partNumber)) {
			testGroups['Live Payload Tests'] = [];
			livePayloadTestKeys.forEach((key) => {
				testGroups['Live Payload Tests'].push(key);
				testNames.push(key);
			});
		}

		// add vehicle appropriate flight tests and mag cal
		if (discoveredPartAssemblyType === 'MILITARY_AIR_VEHICLE_ASSEMBLY') {
			testGroups['Flight Test'] = [];
			vehicleTestKeys.forEach((key) => {
				testGroups['Flight Test'].push(key);
				testNames.push(key);
			});
			testGroups['Magnetometer Calibration'] = [];
			magTestKeys.forEach((key) => {
				testGroups['Magnetometer Calibration'].push(key);
				testNames.push(key);
			});
		}

		// add fcu tests
		if (fcuPartNumbers.includes(partNumber)) {
			fcuTestKeys.forEach((key) => {
				testNames.push(key);
			});
		}

		// setTestGroups(testGroups);
		return testGroups;

		// getData(currentPageQuery).catch(console.error);
	}

	function renderKitCheckoutGroups() {
		return props.kitCheckoutGroup?.map((group, index) => {
			return <KitCheckoutTable kitGroup={group} />;
		});
	}

	async function getGroupedSummaryData(partId: number, testGroups: { [key: string]: string[] }) {
		try {
			const res = await ApiRequestV1.getTestResultSummary({ partId });

			let summaryData: SummaryData[] = [];
			let missingTests = [...expectedTestNamesRef.current];

			if (!res.summary) return;
			for (let i in res.summary) {
				missingTests = missingTests.filter((testName) => testName !== i);
				if (i === 'sessionStart') continue;
				let humanReadableTestName = StringUtils.convertCamelCaseToHuman(i);

				summaryData.push({
					testName: humanReadableTestName,
					status: res.summary[i].status,
					createdOn: res.summary[i].createdOn,
					testResultId: res.summary[i].testResultId
				});
			}

			// Add the missing tests
			for (let testName of missingTests) {
				let humanReadableTestName = StringUtils.convertCamelCaseToHuman(testName);
				summaryData.push({
					testName: humanReadableTestName,
					status: 'INCOMPLETE',
					createdOn: '',
					testResultId: 0
				});
			}

			let groupedSummaryData: {
				group: string;
				tests: {
					testName: string;
					status: 'PASS' | 'FAIL' | 'INCOMPLETE' | 'MISSING';
					createdOn: string;
					testResultId: number;
				}[];
			}[] = [];

			for (let group in testGroups) {
				testGroups[group].forEach((testName) => {
					let test = summaryData.find(
						(test) => test.testName === StringUtils.convertCamelCaseToHuman(testName)
					);
					if (test) {
						let groupIndex = groupedSummaryData.findIndex((groupedTest) => groupedTest.group === group);
						if (groupIndex === -1) {
							groupedSummaryData.push({
								group: group,
								tests: [test]
							});
						} else {
							groupedSummaryData[groupIndex].tests.push(test);
						}
					}
				});
			}

			return groupedSummaryData;
		} catch (e) {
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'Unknown Error'), 'Server Error');
		}
	}

	function renderStatusChip(status: string) {
		if (status === 'INCOMPLETE') {
			return <StatusChip status="WARNING" labelVariant="caption1" isSmall />;
		} else if (status === 'MISSING') {
			return <StatusChip status="INCOMPLETE" labelVariant="caption1" isSmall />;
		} else if (status === 'FAIL') {
			return <StatusChip status="ERROR" labelVariant="caption1" isSmall />;
		} else {
			return <StatusChip status="SUCCESS" labelVariant="caption1" isSmall />;
		}
	}

	function renderTestSummaryRows(subTest: any[]) {
		return subTest.map((row) => {
			return (
				<tr>
					<td>
						<Label variant="caption2" weight="regular">
							{row.testName}
						</Label>
					</td>
					<td>
						<Label variant="caption2" weight="regular">
							{new Date(row.createdOn).toLocaleDateString()}
						</Label>
					</td>
					<td>
						<Label variant="caption2" weight="regular">
							{renderStatusChip(row.status)}
						</Label>
					</td>
				</tr>
			);
		});
	}

	function renderTestSummaryTables(test: any[]) {
		return test.map((item) => {
			return (
				<Box className="testTableContent">
					<Label variant="caption1" weight="semiBold" className="testName">
						{item.group}
					</Label>
					<table>
						<thead>
							<tr className="header">
								<th>
									<Label variant="caption2" weight="regular">
										Test
									</Label>
								</th>
								<th>
									<Label variant="caption2" weight="regular">
										Tested On
									</Label>
								</th>
								<th>
									<Label variant="caption2" weight="regular">
										Status
									</Label>
								</th>
							</tr>
						</thead>
						<tbody>{renderTestSummaryRows(item.tests)}</tbody>
					</table>
				</Box>
			);
		});
	}

	function renderKitTestSummary() {
		if (!ObjectUtils.isArrayWithData(groupedSummaryData)) return;

		let nodeArray = [];
		nodeArray = groupedSummaryData.map((group) => {
			return (
				<Box className="summaryTable">
					<Label variant="h6" weight="bold" mb="4px">
						{group.name}
					</Label>
					<Box className="testTableWrapper">{renderTestSummaryTables(group.testGroup)}</Box>
				</Box>
			);
		});
		return nodeArray;
	}

	return (
		<Box className="rsKitCheckoutPdf" elementRef={props.kitCheckoutPdfRef}>
			<Box className="pdfHeader">
				<Box borderRight={'1px solid var(--Neutral-Grey-400, #CED4DA)'}>
					<Box p="0 8px 8px 0" borderBottom={'1px solid var(--Neutral-Grey-400, #CED4DA)'}>
						<Label variant={'h4'} weight={'semiBold'}>
							Final Kit Verification
						</Label>
					</Box>
					<Box display="grid" gridTemplateColumns="1fr 1fr" p={'8px 0'}>
						<Box>
							<Label variant="caption2" weight="regular">
								Serial Number
							</Label>
							<Label variant="caption1" weight="bold">
								{props.serialNumber}
							</Label>
						</Box>
						<Box>
							<Label variant="caption2" weight="regular">
								Sales Order
							</Label>
							<Label variant="caption1" weight="bold">
								{props.salesOrder}
							</Label>
						</Box>
						<Box>
							<Label variant="caption2" weight="regular">
								Checked By
							</Label>
							<Label variant="caption1" weight="bold">
								{user?.firstName} {user?.lastName}
							</Label>
						</Box>
						<Box>
							<Label variant="caption2" weight="regular">
								Date
							</Label>
							<Label variant="caption1" weight="bold">
								{new Date().toDateString()}
							</Label>
						</Box>
					</Box>
				</Box>
				<Box pb={8}>
					<Img
						src={'/pdfStatic/logo_black_wordmark.png'}
						alt="FLIR Black Logo"
						width="136px"
						height="31px"
						mb={8}
					/>
					<Box>
						<Label variant="caption2" weight="regular">
							100 Midland Road
						</Label>
						<Label variant="caption2" weight="regular">
							Oak Ridge, TN 37830
						</Label>
						<Label variant="caption2" weight="regular">
							T +1.865.220.8700x101
						</Label>
						<Label variant="caption2" weight="regular">
							F +1.865.220.7181
						</Label>
						<Label variant="caption2" weight="regular">
							email@teledyneflir.com
						</Label>
						<Label variant="caption2" weight="regular">
							[URL]
						</Label>
					</Box>
				</Box>
			</Box>
			{!groupedSummaryData.length ? (
				<Box mt="16px" position="relative" height="250px">
					<LoadingSpinner />
				</Box>
			) : (
				<>
					{renderKitCheckoutGroups()}
					{renderKitTestSummary()}
				</>
			)}
		</Box>
	);
};

export default KitCheckoutPdf;
