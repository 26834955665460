import { Box, Button, popupController, RsFormGroup, rsToastify } from '@redskytech/framework/ui';
import { Column } from 'primereact/column';
import { DataTable, DataTableFilterMeta } from 'primereact/datatable';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import TableSearchHeader from '../../../components/listDataTable/tableSearchHeader/TableSearchHeader';
import StatusChip from '../../../components/statusChip/StatusChip';
import useDataTableFilters from '../../../customHooks/useDataTableFilters';
import useIsMounted from '../../../customHooks/useIsMounted';
import { ApiRequestV1 } from '../../../generated/apiRequests';
import TestResultsPopup, { TestResultsPopupProps } from '../../../popups/testResultsPopup/TestResultsPopup';
import serviceFactory from '../../../services/serviceFactory';
import { optionalTestFixtures, TestFixtureType } from '../../../services/testFixture/ITestFixtureService';
import { testFixtureTestMap } from '../../../services/testFixture/testFixture.data';
import { DateUtils, ObjectUtils, StringUtils, WebUtils } from '../../../utils/utils';
import './TestSummaryTable.scss';

import GroupDataTable from '../../../components/groupDataTable/GroupDataTable';
import { fcuTestKeys } from '../../../pages/flightTestPage/sections/FcuFlightTestList';
import { vehicleTestKeys } from '../../../pages/flightTestPage/sections/VehicleFlightTestList';
import { magTestKeys } from '../../../pages/magTestPage/MagTestSection';
import {
	fcuPartNumbers,
	livePayloadPartNumbers,
	trainerPayloadPartNumbers
} from '../../../services/assembly/assembly.data';
import { livePayloadTestKeys } from '../../flightTestPage/sections/LivePayloadFlightTestList';
import { trainerPayloadTestKeys } from '../../flightTestPage/sections/TrainerPayloadFlightTestList';
import { generatePdf } from '../../../utils/pdfUtil';

interface TestSummaryTableProps {
	partId: number;
	partNumber?: string;
	customHeader?: string;
}

const FieldKeys = {
	NAME: 'testResult.testName',
	CREATED_ON: 'testResult.createdOn',
	STATUS: 'testResult.status'
};

export interface SummaryData {
	testName: string;
	status: 'PASS' | 'FAIL' | 'INCOMPLETE';
	createdOn: string;
	testResultId: number;
}

const TestSummaryTable: React.FC<TestSummaryTableProps> = (props) => {
	const [testResults, setTestResults] = useState<RedSky.RsPagedResponseData<SummaryData[]>>({
		data: [],
		total: 0
	});
	const [testGroups, setTestGroups] = useState<{ [key: string]: [] }>({});
	const [groupedSummaryData, setGroupedSummaryData] = useState<any[]>([]);
	const [currentPageQuery, setCurrentPageQuery] = useState<RedSky.PageQuery>({
		page: 1,
		perPage: 100
	});
	const expectedTestNamesRef = useRef<string[]>([]);

	const initialFilters: DataTableFilterMeta = {};
	const initialFiltersFormGroup: RsFormGroup = new RsFormGroup([]);

	const { globalSearch } = useDataTableFilters(initialFilters, initialFiltersFormGroup, getUpdatedFilters);
	const isMounted = useIsMounted();

	useEffect(() => {
		if (!props.partId || !props.partNumber) return;

		// Use the part number to get a list of all the tests that should have ran for that part number
		// Then use that list to determine which tests are missing from the summary data
		// Then add those tests to the summary data with a status of INCOMPLETE
		const assemblyService = serviceFactory.get('AssemblyService');
		const discoveredPartAssemblyType = assemblyService.getPartAssemblyTypeFromPartNumber(props.partNumber);

		let testNames: string[] = [];
		let testGroups: any = {};
		if (discoveredPartAssemblyType && testFixtureTestMap.hasOwnProperty(discoveredPartAssemblyType)) {
			let fixture: TestFixtureType;
			for (fixture in testFixtureTestMap[discoveredPartAssemblyType]) {
				if (
					!testFixtureTestMap[discoveredPartAssemblyType].hasOwnProperty(fixture) ||
					optionalTestFixtures.includes(fixture)
				)
					continue;

				testGroups[testFixtureTestMap[discoveredPartAssemblyType][fixture]!.name] = [];
				for (let test of testFixtureTestMap[discoveredPartAssemblyType][fixture]!.tests) {
					if (test.auditRequired) {
						testGroups[testFixtureTestMap[discoveredPartAssemblyType][fixture]!.name].push(test.testName);
						testNames.push(test.testName);
					}
				}
			}
		}

		// add payload appropriate flight tests
		if (trainerPayloadPartNumbers.includes(props.partNumber)) {
			testGroups['Trainer Payload Tests'] = [];
			trainerPayloadTestKeys.forEach((key) => {
				testGroups['Trainer Payload Tests'].push(key);
				testNames.push(key);
			});
		}

		// add payload appropriate flight tests
		if (livePayloadPartNumbers.includes(props.partNumber)) {
			testGroups['Live Payload Tests'] = [];
			livePayloadTestKeys.forEach((key) => {
				testGroups['Live Payload Tests'].push(key);
				testNames.push(key);
			});
		}

		// add vehicle appropriate flight tests and mag cal
		if (discoveredPartAssemblyType === 'MILITARY_AIR_VEHICLE_ASSEMBLY') {
			testGroups['Flight Test'] = [];
			vehicleTestKeys.forEach((key) => {
				testGroups['Flight Test'].push(key);
				testNames.push(key);
			});
			testGroups['Magnetometer Calibration'] = [];
			magTestKeys.forEach((key) => {
				testGroups['Magnetometer Calibration'].push(key);
				testNames.push(key);
			});
		}

		// add fcu tests
		if (fcuPartNumbers.includes(props.partNumber)) {
			fcuTestKeys.forEach((key) => {
				testNames.push(key);
			});
		}

		// setExpectedTestNames(testNames);
		expectedTestNamesRef.current = testNames;
		setTestGroups(testGroups);

		getData(currentPageQuery).catch(console.error);
	}, [props.partId, props.partNumber]);

	function getUpdatedFilters(field: string, value: any) {
		return {};
	}

	async function getData(pageQuery: RedSky.PageQuery): Promise<void> {
		setCurrentPageQuery(pageQuery);
		try {
			if (!isMounted || !props.partId || !props.partNumber) return;

			const res = await ApiRequestV1.getTestResultSummary({ partId: props.partId });

			let summaryData: SummaryData[] = [];
			let missingTests = [...expectedTestNamesRef.current];

			if (!res.summary) return;
			for (let i in res.summary) {
				missingTests = missingTests.filter((testName) => testName !== i);
				if (i === 'sessionStart') continue;
				let humanReadableTestName = StringUtils.convertCamelCaseToHuman(i);

				// Use the pageQuery to adjust the data
				if (globalSearch && !humanReadableTestName.toLowerCase().includes(globalSearch.toLowerCase())) continue;

				summaryData.push({
					testName: humanReadableTestName,
					status: res.summary[i].status,
					createdOn: res.summary[i].createdOn,
					testResultId: res.summary[i].testResultId
				});
			}

			// Add the missing tests
			for (let testName of missingTests) {
				let humanReadableTestName = StringUtils.convertCamelCaseToHuman(testName);
				summaryData.push({
					testName: humanReadableTestName,
					status: 'INCOMPLETE',
					createdOn: '',
					testResultId: 0
				});
			}

			if (pageQuery.sortBy && pageQuery.sortOrder) {
				summaryData.sort((a, b) => {
					if (pageQuery.sortBy === FieldKeys.NAME) {
						return pageQuery.sortOrder === 'ASC'
							? a.testName.localeCompare(b.testName)
							: b.testName.localeCompare(a.testName);
					}
					if (pageQuery.sortBy === FieldKeys.CREATED_ON) {
						return pageQuery.sortOrder === 'ASC'
							? a.createdOn.localeCompare(b.createdOn)
							: b.createdOn.localeCompare(a.createdOn);
					}
					if (pageQuery.sortBy === FieldKeys.STATUS) {
						return pageQuery.sortOrder === 'ASC'
							? a.status.localeCompare(b.status)
							: b.status.localeCompare(a.status);
					}
					return 0;
				});
			}

			let groupedSummaryData: any[] = [];

			for (let group in testGroups) {
				testGroups[group].forEach((testName) => {
					let test = summaryData.find(
						(test) => test.testName === StringUtils.convertCamelCaseToHuman(testName)
					);
					if (test) {
						let groupIndex = groupedSummaryData.findIndex((groupedTest) => groupedTest.group === group);
						if (groupIndex === -1) {
							groupedSummaryData.push({
								group: group,
								tests: [test]
							});
						} else {
							groupedSummaryData[groupIndex].tests.push(test);
						}
					}
				});
			}

			setGroupedSummaryData(groupedSummaryData);

			setTestResults({
				data: summaryData,
				total: 0
			});
		} catch (e) {
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'Unknown Error'), 'Server Error');
		}
	}

	async function handleShowDetails(summaryData: SummaryData) {
		popupController.open<TestResultsPopupProps>(TestResultsPopup, {
			testName: summaryData.testName,
			testResultId: summaryData.testResultId
		});
	}

	if (!ObjectUtils.isArrayWithData(expectedTestNamesRef.current)) return null;

	function rowExpansionTemplate(data: any) {
		return (
			//Has to be DataTable to work with Expansion. For some reason RsDataTable doesn't work with Expansion - Alex
			<DataTable {...props} value={data.tests}>
				<Column field="testName" header="Test Name" body={(testResult: SummaryData) => testResult.testName} />
				<Column
					sortable
					field="createdOn"
					header="Created On"
					body={(summaryData: SummaryData) => {
						if (!summaryData.createdOn) return 'N/A';
						return (
							<Box className="clickableDate" onClick={() => handleShowDetails(summaryData)}>
								{DateUtils.displayDate(summaryData.createdOn)}
							</Box>
						);
					}}
				/>
				<Column
					sortable
					field="status"
					header="Status"
					body={(summaryData: SummaryData) => {
						if (summaryData.status === 'PASS') return <StatusChip status={'SUCCESS'} />;
						if (summaryData.status === 'FAIL') return <StatusChip status={'ERROR'} />;
						return <StatusChip status={'PENDING'} />;
					}}
				/>
			</DataTable>
		);
	}

	return (
		<Box className={'rsTestSummaryTable'}>
			<GroupDataTable
				data={{ data: groupedSummaryData, total: groupedSummaryData.length }}
				getData={getData}
				rowExpansionTemplate={rowExpansionTemplate}
				header={<TableSearchHeader placeholder={''} title={props.customHeader || 'Test Results Summary'} />}
			>
				<Column expander />
				<Column field="group" header="Test" />
				<Column
					field="status"
					header="Status"
					body={(testGroup) => {
						if (testGroup.tests.some((test: SummaryData) => test.status === 'FAIL'))
							return <StatusChip status={'ERROR'} />;
						else if (testGroup.tests.some((test: SummaryData) => test.status === 'INCOMPLETE'))
							return <StatusChip status={'PENDING'} />;
						else return <StatusChip status={'SUCCESS'} />;
					}}
				/>
			</GroupDataTable>
		</Box>
	);
};

export default TestSummaryTable;
