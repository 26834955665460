import { rsToastify } from '@redskytech/framework/ui';
import { ApiRequestV1 } from '../generated/apiRequests';
import { fcuTestKeys } from '../pages/flightTestPage/sections/FcuFlightTestList';
import { livePayloadTestKeys } from '../pages/flightTestPage/sections/LivePayloadFlightTestList';
import { trainerPayloadTestKeys } from '../pages/flightTestPage/sections/TrainerPayloadFlightTestList';
import { vehicleTestKeys } from '../pages/flightTestPage/sections/VehicleFlightTestList';
import { magTestKeys } from '../pages/magTestPage/MagTestSection';
import {
	assemblyStructure,
	fcuPartNumbers,
	livePayloadFlightTestPartNumbers,
	livePayloadPartNumbers,
	trainerPayloadPartNumbers
} from '../services/assembly/assembly.data';
import { BasePartType, PartAssemblyType } from '../services/assembly/IAssemblyService';
import serviceFactory from '../services/serviceFactory';
import { optionalTestFixtures, TestFixtureType } from '../services/testFixture/ITestFixtureService';
import { testFixtureTestMap } from '../services/testFixture/testFixture.data';
import { StringUtils } from './utils';
import test from 'node:test';

export interface AuditTestResultSummary {
	group: string;
	tests: {
		testName: string;
		status: 'PASS' | 'FAIL' | 'INCOMPLETE' | 'MISSING';
		createdOn: string;
		testResultId: number;
	}[];
}

export async function getAllTestDataByPartId(partId: number) {
	let masterTestList: AuditTestResultSummary[] = [];

	async function checkAssemblyTests(partIdToCheck: number): Promise<void> {
		const assemblyService = serviceFactory.get('AssemblyService');
		try {
			let res = await ApiRequestV1.getPart({ partId: partIdToCheck });
			let discoveredAssemblyType =
				assemblyService.getPartAssemblyTypeFromPartNumber(res.partNumber) ||
				assemblyService.getBasePartTypeFromPartNumber(res.partNumber);

			if (!discoveredAssemblyType) {
				rsToastify.error('Unable to recognize this assembly.', 'Unknown Assembly Type');
				return;
			}

			let officialTestList: string[] = [];
			if (testFixtureTestMap[discoveredAssemblyType as PartAssemblyType]) {
				let fixture: TestFixtureType;
				for (fixture in testFixtureTestMap[discoveredAssemblyType as PartAssemblyType]) {
					if (!optionalTestFixtures.includes(fixture)) {
						for (let test of testFixtureTestMap[discoveredAssemblyType as PartAssemblyType][fixture]!
							.tests) {
							if (test.auditRequired) {
								officialTestList.push(test.testName);
							}
						}
					}
				}
			}

			if (trainerPayloadPartNumbers.includes(res.partNumber)) {
				officialTestList.push(...trainerPayloadTestKeys);
			}

			if (livePayloadFlightTestPartNumbers.includes(res.partNumber)) {
				officialTestList.push(...livePayloadTestKeys);
			}

			if (discoveredAssemblyType === 'MILITARY_AIR_VEHICLE_ASSEMBLY') {
				officialTestList.push(...vehicleTestKeys, ...magTestKeys);
			}

			if (fcuPartNumbers.includes(res.partNumber)) {
				officialTestList.push(...fcuTestKeys);
			}

			// We don't need to retrieve test results for assemblies that we don't audit any tests for.
			if (officialTestList.length === 0) return;

			const testResSummary = await ApiRequestV1.getTestResultSummary({ partId: partIdToCheck });
			if (!testResSummary) {
				rsToastify.error('Error checking assembly tests', 'Error');
				return;
			}

			let testPartArray: AuditTestResultSummary['tests'] = [];

			if (officialTestList && testResSummary.summary !== undefined) {
				officialTestList.forEach((officialTestName) => {
					if (testResSummary.summary[officialTestName] === undefined) {
						testPartArray.push({
							testName: StringUtils.convertCamelCaseToHuman(officialTestName),
							status: 'INCOMPLETE',
							testResultId: -1,
							createdOn: ''
						});
					} else {
						testPartArray.push({
							testName: StringUtils.convertCamelCaseToHuman(officialTestName),
							status: testResSummary.summary[officialTestName].status,
							testResultId: testResSummary.summary[officialTestName].testResultId,
							createdOn: testResSummary.summary[officialTestName].createdOn
						});
					}
				});
				masterTestList.push({
					group: `${assemblyService.getLabelFromPartNumber(res.partNumber)}`,
					tests: [...testPartArray]
				});
			}

			const assemblyComponents = assemblyStructure[discoveredAssemblyType as PartAssemblyType];
			if (!assemblyComponents || assemblyComponents.length === 0) return;

			let subassemblyChecklist = assemblyComponents.reduce((obj: any, item: PartAssemblyType | BasePartType) => {
				obj[item] = 'false';
				return obj;
			}, {});

			const childTestPromises = res.children.map(async (child) => {
				let childPartAssemblyType =
					assemblyService.getPartAssemblyTypeFromPartNumber(child.childPartNumber) ||
					assemblyService.getBasePartTypeFromPartNumber(child.childPartNumber);

				if (!childPartAssemblyType) {
					rsToastify.error(
						`Unable to recognize child subassembly type. ${child.childPartNumber}`,
						'Unknown Assembly Type'
					);
					return;
				}

				if (subassemblyChecklist.hasOwnProperty(childPartAssemblyType)) {
					subassemblyChecklist[childPartAssemblyType] = 'true';
				}

				if (discoveredAssemblyType === 'MAIN_BOARD_ASSEMBLY' && childPartAssemblyType.includes('MAIN_BOARD')) {
					return;
				}

				await checkAssemblyTests(child.childId);
			});

			await Promise.all(childTestPromises);
		} catch (error) {
			rsToastify.error('Error during test audit check', 'Error');
			console.error(error);
		}
	}

	await checkAssemblyTests(partId);

	return masterTestList;
}

// async function getAllTestDataByPartId(partId: number) {
// 	let masterTestList: string[] = [];

// 	async function checkAssemblyTests(partIdToCheck: number) {
// 		let res = await ApiRequestV1.getPart({ partId: partIdToCheck });

// 		let discoveredAssemblyType: PartAssemblyType | BasePartType | undefined;
// 		const testResSummary = await ApiRequestV1.getTestResultSummary({ partId: partIdToCheck });
// 		if (!testResSummary) {
// 			rsToastify.error('Error checking assembly tests', 'Error');
// 			return;
// 		}

// 		discoveredAssemblyType = assemblyService.getPartAssemblyTypeFromPartNumber(res.partNumber);
// 		if (!discoveredAssemblyType) {
// 			discoveredAssemblyType = assemblyService.getBasePartTypeFromPartNumber(res.partNumber);
// 			if (discoveredAssemblyType) {
// 				// This is a base component, and has no children, so just return
// 				return;
// 			} else {
// 				rsToastify.error('Unable to recognize this assembly.', 'Unknown Assembly Type');
// 				return;
// 			}
// 		}

// 		// get the full list of the tests based on the code.
// 		let officialTestList: string[] = [];
// 		if (discoveredAssemblyType && testFixtureTestMap.hasOwnProperty(discoveredAssemblyType)) {
// 			let fixture: TestFixtureType;
// 			for (fixture in testFixtureTestMap[discoveredAssemblyType]) {
// 				if (
// 					!testFixtureTestMap[discoveredAssemblyType].hasOwnProperty(fixture) ||
// 					optionalTestFixtures.includes(fixture)
// 				)
// 					continue;
// 				for (let test of testFixtureTestMap[discoveredAssemblyType][fixture]!.tests) {
// 					officialTestList.push(test.testName);
// 				}
// 			}
// 		}

// 		// add payload appropriate flight tests
// 		if (trainerPayloadPartNumbers.includes(res.partNumber)) {
// 			trainerPayloadTestKeys.forEach((key) => {
// 				officialTestList.push(key);
// 			});
// 		}

// 		// add payload appropriate flight tests
// 		if (livePayloadPartNumbers.includes(res.partNumber)) {
// 			livePayloadTestKeys.forEach((key) => {
// 				officialTestList.push(key);
// 			});
// 		}

// 		// add vehicle appropriate flight tests and mag cal
// 		if (discoveredAssemblyType === 'MILITARY_AIR_VEHICLE_ASSEMBLY') {
// 			vehicleTestKeys.forEach((key) => {
// 				officialTestList.push(key);
// 			});
// 			magTestKeys.forEach((key) => {
// 				officialTestList.push(key);
// 			});
// 		}

// 		// add fcu tests
// 		if (fcuPartNumbers.includes(res.partNumber)) {
// 			fcuTestKeys.forEach((key) => {
// 				officialTestList.push(key);
// 			});
// 		}

// 		let missingTests: string[] = [];
// 		let failedTests: string[] = [];
// 		let incompleteTests: string[] = [];
// 		officialTestList.forEach((test) => {
// 			if (!testResSummary.summary.hasOwnProperty(test)) {
// 				missingTests.push(test);
// 			} else if (testResSummary.summary[test].status === 'FAIL') {
// 				failedTests.push(test);
// 			} else if (testResSummary.summary[test].status === 'INCOMPLETE') {
// 				incompleteTests.push(test);
// 			}
// 		});

// 		if (missingTests.length || failedTests.length || incompleteTests.length) {
// 			const partLabel = `${assemblyService.getLabelFromPartNumber(res.partNumber)}:`;
// 			masterTestList.push(partLabel);
// 		}
// 		masterTestList = [...masterTestList, ...incompleteTests, ...failedTests, ...missingTests];

// 		const assemblyComponents = assemblyStructure[discoveredAssemblyType];

// 		if (!assemblyComponents) {
// 			rsToastify.error('Unable to recognize this assembly.', 'Unknown Assembly Type');
// 			return;
// 		}

// 		// This shouldn't happen unless we have an assembly with no children it is not a base part.
// 		if (assemblyComponents.length === 0) {
// 			return;
// 		}

// 		// make a dictionary with a key for every item in required subassemblies, all set to false
// 		let subassemblyChecklist = assemblyComponents.reduce((obj: any, item: PartAssemblyType | BasePartType) => {
// 			obj[item] = 'false';
// 			return obj;
// 		}, {});

// 		const partChildren = res.children;

// 		// check for children, and call it for all of the children
// 		partChildren.forEach((child) => {
// 			let childPartAssemblyType: PartAssemblyType | BasePartType | undefined;
// 			childPartAssemblyType = assemblyService.getPartAssemblyTypeFromPartNumber(child.childPartNumber);
// 			if (!childPartAssemblyType) {
// 				childPartAssemblyType = assemblyService.getBasePartTypeFromPartNumber(child.childPartNumber);
// 				if (!childPartAssemblyType) {
// 					rsToastify.error(
// 						`Unable to recognize child subassembly type. ${child.childPartNumber}`,
// 						'Unknown Assembly Type'
// 					);
// 				}
// 			}

// 			if (childPartAssemblyType && subassemblyChecklist.hasOwnProperty(childPartAssemblyType)) {
// 				subassemblyChecklist[childPartAssemblyType] = 'true';
// 			}

// 			// special check to deal with main board assemblies
// 			if (
// 				childPartAssemblyType &&
// 				childPartAssemblyType.includes('MAIN_BOARD') &&
// 				subassemblyChecklist.hasOwnProperty('MAIN_BOARD')
// 			) {
// 				subassemblyChecklist['MAIN_BOARD'] = 'true';
// 			}

// 			// if the parent is a main board assembly, and the child is a main board assembly, we don't want to check it
// 			if (
// 				discoveredAssemblyType === 'MAIN_BOARD_ASSEMBLY' &&
// 				childPartAssemblyType &&
// 				childPartAssemblyType.includes('MAIN_BOARD')
// 			)
// 				return;

// 			checkAssemblyTests(child.childId).catch((err) => {
// 				rsToastify.error('Error checking Sub assembly for test audit', 'Error');
// 				console.error(err);
// 			});
// 		});
// 	}

//     console.log("masterTestList", masterTestList);
// }

// export function generateAuditList(testStringList: string[]) {
// 	// format the tests to be human readable
// 	if (testStringList.length === 0) return [];
// 	if (testStringList.length === 1) return [{ group: testStringList[0], errors: [] }];
// 	let outputObjects: AuditErrorSection[] = [];
// 	let newoutputObject: AuditErrorSection = { group: '', errors: [] };
// 	testStringList.forEach((test, index) => {
// 		if (test.endsWith(':')) {
// 			// indicates a new group, if the old group name was anything other than '' then lets push it
// 			if (newoutputObject.group !== '') {
// 				outputObjects.push(newoutputObject);
// 				newoutputObject = { group: '', errors: [] };
// 			}
// 			newoutputObject.group = test.replace(':', '');
// 		} else {
// 			newoutputObject.errors.push(StringUtils.convertCamelCaseToHuman(test));
// 		}
// 	});
// 	if (newoutputObject.group !== '') {
// 		outputObjects.push(newoutputObject);
// 		newoutputObject = { group: '', errors: [] };
// 	}
// 	return outputObjects;
// }
