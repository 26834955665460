import { Service } from '../Service';
import globalState, { getRecoilExternalValue, setRecoilExternalValue } from '../../state/globalState';
import { TestFixtureType, TestResult } from './ITestFixtureService';
import { testFixtures, testFixtureTestMap } from './testFixture.data';
import { RsFormControl, RsFormGroup, rsToastify } from '@redskytech/framework/ui';
import { TestKey } from '../../pages/flightTestPage/sections/FlightTestSection';
import { IRsFormControl } from '@redskytech/framework/ui/form/FormControl';
import { EvaluationOptions, EvaluationGroup } from '../../components/evaluationItem/EvaluationItem';

export default class TestFixtureService extends Service {
	getTestFixtureTypeFromPartNumber(partNumber: string): TestFixtureType | undefined {
		let testFixtureKey: TestFixtureType;
		for (testFixtureKey in testFixtures) {
			if (testFixtures[testFixtureKey].partNumbers.includes(partNumber)) return testFixtureKey;
		}
		return undefined;
	}

	getListOfTestsByTestName(testName: string): string[] {
		let testList: string[] = [];
		let assemblyKey: keyof typeof testFixtureTestMap;
		for (assemblyKey in testFixtureTestMap) {
			let testFixtureKey: keyof typeof testFixtureTestMap[typeof assemblyKey];
			for (testFixtureKey in testFixtureTestMap[assemblyKey]) {
				if (testFixtureTestMap[assemblyKey][testFixtureKey]!.name === testName) {
					testFixtureTestMap[assemblyKey][testFixtureKey]!.tests.forEach((test) => {
						testList.push(test.testName);
					});
				}
			}
		}
		return testList;
	}

	nextTest() {
		setRecoilExternalValue(globalState.testFixtureStatus, (prev) => {
			return {
				...prev,
				currentTestIndex: prev.currentTestIndex + 1
			};
		});
	}

	isNumber(str: string) {
		const numberRegex = /^[-+]?[0-9]{1,3}(,[0-9]{3})*(\.[0-9]*)?$/;
		return numberRegex.test(str);
	}

	controlValueToNumber(control: RsFormControl<IRsFormControl>): number | undefined {
		if (typeof control.value === 'number') {
			// control.value is a number
			return control.value;
		} else if (typeof control.value === 'string') {
			// if the string contains extra info that isn't a number then we should return false
			if (!this.isNumber(control.value)) return undefined;

			let value = parseFloat(control.value);
			// Check for Nan, which shouldn't really ever happen at this point.
			if (isNaN(value)) {
				return undefined;
			}
			return value;
		}
	}

	// Check if a number is in a range ( inclusive )
	validateResultInRange(
		control: RsFormControl<IRsFormControl>,
		minValue: number,
		maxValue: number,
		absVal: boolean = false
	) {
		//
		// default to an invalid value.  This will be overridden if the value is valid
		let value: number = -1;
		if (control.value) {
			if (typeof control.value === 'number') {
				// control.value is a number
				value = control.value;
			} else if (typeof control.value === 'string') {
				// if the string contains extra info that isn't a number then we should return false
				if (!this.isNumber(control.value)) return false;

				value = parseFloat(control.value);
				// Check for Nan, which shouldn't really ever happen at this point.
				if (isNaN(value)) {
					return false;
				}
			} else {
				rsToastify.error('Value must be a number', 'Test Value type error.');
			}

			if (absVal) {
				value = Math.abs(value);
			}

			return !(value < minValue || value > maxValue);
		}
		return false;
	}

	// In this code Worst means a higher abs value, and best means a lower abs value
	// Check if a number is in a range ( inclusive )
	validateAbsResultRssiInRangeWithRelative(
		currentValue: number,
		absBestValueMax: number,
		absWorstValueMax: number,
		neighborValue: number,
		absMaxNeighborDelta: number,
		aligned: boolean = false
	): boolean {
		let valueAbs = Math.abs(currentValue);
		let neighborValueAbs = Math.abs(neighborValue);
		// If the aircraft is aligned we have a minimum threshold
		if (aligned) {
			// If there is a gap between the value and the neighbor value that is greater than the max delta, return false
			if (Math.abs(valueAbs - neighborValueAbs) > absMaxNeighborDelta) {
				return false;
			}
		} else {
			if (Math.abs(valueAbs - neighborValueAbs) < absMaxNeighborDelta) {
				return false;
			}
		}
		// Identify the correct threshold, if we are the biggest value, use the worst value threshold, otherwise use the best value threshold
		let threshold = valueAbs <= neighborValueAbs ? absBestValueMax : absWorstValueMax;

		return valueAbs <= threshold;
	}

	hasPropertyValue(value: EvaluationOptions | undefined, evaluations: EvaluationGroup) {
		for (let key in evaluations) {
			if (evaluations[key] === value) {
				return true;
			}
		}
		return false;
	}

	validateEvaluationTest(testName: string, evaluations: EvaluationGroup, overrideField?: undefined | string) {
		let prevResult = getRecoilExternalValue(globalState.testResults).findIndex(
			(prevRes) => prevRes.testName === testName
		);
		if (this.hasPropertyValue(undefined, evaluations)) return;
		let hasFail = this.hasPropertyValue('FAILURE', evaluations);

		if (hasFail && overrideField && overrideField in evaluations) {
			// This allows us to say that the item has passed no matter what other items in the group say!
			hasFail = !(evaluations[overrideField] === 'PASS');
		}

		setRecoilExternalValue(globalState.testResults, (prevState) => {
			let updatedState = [...prevState];
			if (prevResult < 0) {
				let result: TestResult = {
					testName: testName,
					data: evaluations,
					timeStamp: new Date().toLocaleTimeString([], {
						hour: '2-digit',
						minute: '2-digit',
						second: '2-digit',
						hour12: true
					}),
					passed: !hasFail,
					continuable: true
				};
				return [...updatedState, result];
			}
			updatedState[prevResult] = {
				...updatedState[prevResult],
				data: evaluations,
				timeStamp: new Date().toLocaleTimeString([], {
					hour: '2-digit',
					minute: '2-digit',
					second: '2-digit',
					hour12: true
				}),
				passed: !hasFail
			};
			return updatedState;
		});
	}

	async validateFlightTest(
		formGroup: RsFormGroup,
		testName: TestKey,
		validateAll: boolean = false,
		overrideField?: undefined | string
	) {
		let controls = formGroup.getControls();
		let prevResult = getRecoilExternalValue(globalState.testResults).findIndex(
			(prevRes) => prevRes.testName === testName
		);
		if (
			!validateAll &&
			controls.some((control) => {
				return typeof control.value === 'string' && control.value.trim() === '';
			}) &&
			prevResult < 0
		) {
			return;
		}

		let isValid = false;

		isValid = await formGroup.isValid(true);

		if (!isValid && overrideField) {
			try {
				isValid = formGroup.get(overrideField).value === true;
			} catch (e) {
				console.log('unable to find override field in formgroup', e);
			}
		}
		setRecoilExternalValue(globalState.testResults, (prevState) => {
			let updatedState = [...prevState];
			if (prevResult < 0) {
				let result: TestResult = {
					testName: testName,
					data: formGroup.toModel(),
					timeStamp: new Date().toLocaleTimeString([], {
						hour: '2-digit',
						minute: '2-digit',
						second: '2-digit',
						hour12: true
					}),
					passed: isValid,
					continuable: true
				};
				return [...updatedState, result];
			}
			updatedState[prevResult] = {
				...updatedState[prevResult],
				data: formGroup.toModel(),
				timeStamp: new Date().toLocaleTimeString([], {
					hour: '2-digit',
					minute: '2-digit',
					second: '2-digit',
					hour12: true
				}),
				passed: isValid
			};
			return updatedState;
		});
	}
}
