import { Page } from '@redskytech/framework/996';
import {
	Box,
	Button,
	Checkbox,
	Label,
	LabelInputText,
	LabelSelect,
	RadioButtonGroup,
	RsFormControl,
	RsFormGroup,
	rsToastify,
	RsValidator,
	RsValidatorEnum
} from '@redskytech/framework/ui';
import * as React from 'react';
import { useState } from 'react';
import PageHeader from '../../components/pageHeader/PageHeader';
import { getLabelStickerAssemblies } from '../../services/assembly/assembly.data';
import serviceFactory from '../../services/serviceFactory';
import colors from '../../themes/colors.scss?export';
import './LabelPage.scss';
interface LabelPageProps {}

enum HwidFormKeys {
	HW_REV = 'hwRev',
	PART_NUMBER = 'partNumber',
	LOCATION = 'location',
	PREFIX = 'prefix',
	STARTING_NUMBER = 'startingNumber',
	WEEK = 'week',
	YEAR = 'year',
	QUANTITY = 'quantity'
}

export interface HwidFormModel {
	hwRev: string;
	partNumber: string;
	location: string;
	prefix: string;
	startingNumber: string;
	week: string;
	year: string;
	quantity: string;
}

enum CageCodeFormKeys {
	CAGE_CODE = 'cageCode',
	DATE = 'date',
	QUANTITY = 'quantity'
}

export interface CageCodeFormModel {
	cageCode: string;
	date: string;
	quantity: string;
}

enum PrinterParamsKeys {
	VERTICAL_SHIFT = 'verticalShift',
	HORIZONTAL_SHIFT = 'horizontalShift',
	DARKNESS = 'darkness',
	SPEED = 'speed',
	DOUBLE_HORIZONTAL = 'doubleHorizontal',
	SIZE = 'size'
}

export interface PrinterParamsModel {
	verticalShift: string;
	horizontalShift: string;
	darkness: string;
	speed: string;
	doubleHorizontal: boolean;
	size: 'NORMAL' | 'SMALL';
}

const LabelPage: React.FC<LabelPageProps> = () => {
	const labelService = serviceFactory.get('LabelService');

	const [printerParamsFormGroup, setPrinterParamsFormGroup] = useState<RsFormGroup>(
		new RsFormGroup([
			new RsFormControl(PrinterParamsKeys.VERTICAL_SHIFT, '10', [
				new RsValidator(RsValidatorEnum.REQ, 'Required'),
				new RsValidator(RsValidatorEnum.CUSTOM, 'Too small', (control): boolean => {
					return parseInt((control.value as string) || '0') >= -120;
				}),
				new RsValidator(RsValidatorEnum.CUSTOM, 'Too large', (control): boolean => {
					return parseInt((control.value as string) || '0') <= 120;
				})
			]),
			new RsFormControl(PrinterParamsKeys.HORIZONTAL_SHIFT, '0', [
				new RsValidator(RsValidatorEnum.REQ, 'Required'),
				new RsValidator(RsValidatorEnum.CUSTOM, 'Too small', (control): boolean => {
					return parseInt((control.value as string) || '0') >= -600;
				}),
				new RsValidator(RsValidatorEnum.CUSTOM, 'Too large', (control): boolean => {
					return parseInt((control.value as string) || '0') <= 600;
				})
			]),
			new RsFormControl(PrinterParamsKeys.DARKNESS, '25', [
				new RsValidator(RsValidatorEnum.REQ, 'Required'),
				new RsValidator(RsValidatorEnum.CUSTOM, 'Too small', (control): boolean => {
					return parseInt((control.value as string) || '0') >= 10;
				}),
				new RsValidator(RsValidatorEnum.CUSTOM, 'Too large', (control): boolean => {
					return parseInt((control.value as string) || '0') <= 30;
				})
			]),
			new RsFormControl(PrinterParamsKeys.SPEED, '3', [
				new RsValidator(RsValidatorEnum.REQ, 'Required'),
				new RsValidator(RsValidatorEnum.CUSTOM, 'Too small', (control): boolean => {
					return parseInt((control.value as string) || '0') >= 1;
				}),
				new RsValidator(RsValidatorEnum.CUSTOM, 'Too large', (control): boolean => {
					return parseInt((control.value as string) || '0') <= 4;
				})
			]),
			new RsFormControl(PrinterParamsKeys.DOUBLE_HORIZONTAL, true, []),
			new RsFormControl(PrinterParamsKeys.SIZE, 'NORMAL', [])
		])
	);

	const [hwidFormGroup, setHwidFormGroup] = useState<RsFormGroup>(
		new RsFormGroup([
			new RsFormControl(HwidFormKeys.HW_REV, '', [
				new RsValidator(RsValidatorEnum.REQ, 'Required'),
				new RsValidator(RsValidatorEnum.MAX_LENGTH, 'Too long', 5)
			]),
			new RsFormControl(HwidFormKeys.PART_NUMBER, '', [new RsValidator(RsValidatorEnum.REQ, 'Required')]),
			new RsFormControl(HwidFormKeys.LOCATION, '', [new RsValidator(RsValidatorEnum.REQ, 'Required')]),
			new RsFormControl(HwidFormKeys.PREFIX, '', [new RsValidator(RsValidatorEnum.MAX_LENGTH, 'Too long', 1)]),
			new RsFormControl(HwidFormKeys.STARTING_NUMBER, '', [
				new RsValidator(RsValidatorEnum.REQ, 'Required'),
				new RsValidator(RsValidatorEnum.MAX_LENGTH, 'Too long', 5),
				new RsValidator(RsValidatorEnum.CUSTOM, 'Prefix + Serial + Quantity too large', (control): boolean => {
					const maxSerialNumber =
						hwidFormGroup.get<string>(HwidFormKeys.PREFIX).value.toString().length > 0 ? 9999 : 99999;
					const startingNumber = parseInt((control.value as string) || '0');
					const quantity = parseInt(hwidFormGroup.get<string>(HwidFormKeys.QUANTITY).value);
					return startingNumber + quantity - 1 <= maxSerialNumber;
				})
			]),
			new RsFormControl(HwidFormKeys.WEEK, getInitialWeekStr(), [
				new RsValidator(RsValidatorEnum.REQ, 'Required')
			]),
			new RsFormControl(HwidFormKeys.YEAR, getInitialYearStr(), [
				new RsValidator(RsValidatorEnum.REQ, 'Required'),
				new RsValidator(RsValidatorEnum.MIN_LENGTH, 'Too short', 2)
			]),
			new RsFormControl(HwidFormKeys.QUANTITY, '1', [
				new RsValidator(RsValidatorEnum.REQ, 'Required'),
				new RsValidator(RsValidatorEnum.MAX_LENGTH, 'Too long', 3)
			])
		])
	);

	const [cageCodeFormGroup, setCageCodeFormGroup] = useState<RsFormGroup>(
		new RsFormGroup([
			new RsFormControl(CageCodeFormKeys.CAGE_CODE, '(17V) 5JHA8', [
				new RsValidator(RsValidatorEnum.REQ, 'Required')
			]),
			new RsFormControl(CageCodeFormKeys.DATE, getInitialStartDateStr(), [
				new RsValidator(RsValidatorEnum.REQ, 'Required'),
				new RsValidator(RsValidatorEnum.MIN_LENGTH, 'Too short', 8),
				new RsValidator(RsValidatorEnum.MAX_LENGTH, 'Too long', 8)
			]),
			new RsFormControl(CageCodeFormKeys.QUANTITY, '1', [
				new RsValidator(RsValidatorEnum.REQ, 'Required'),
				new RsValidator(RsValidatorEnum.MAX_LENGTH, 'Too long', 3)
			])
		])
	);

	const [partSerialOptions, setPartSerialOptions] = useState<{ label: string; value: string }[]>(
		getLabelStickerAssemblies()
			.map((value) => {
				return {
					label: `${value.partNumbers[0]} ${value.label}`,
					value: `${value.partNumbers[0]}`
				};
			})
			.sort((a, b) => a.value.localeCompare(b.value))
	);

	const [locationOptions, setLocationOptions] = useState<{ label: string; value: string }[]>([
		{ label: 'OAK', value: 'OAK' },
		{ label: 'BZN', value: 'BZN' },
		{ label: 'SLC', value: 'SLC' },
		{ label: 'IMS', value: 'IMS' },
		{ label: 'NGC', value: 'NGC' },
		{ label: 'WBS', value: 'WBS' },
		{ label: 'WCS', value: 'WCS' },
		{ label: 'WFC', value: 'WFC' },
		{ label: 'WAA', value: 'WAA' },
		{ label: 'WAB', value: 'WAB' },
		{ label: 'WAC', value: 'WAC' },
		{ label: 'WAD', value: 'WAD' }
	]);

	const [previewLabelStr, setPreviewLabelStr] = useState<string>('Complete form to see preview');
	const [exampleHwidStr, setExampleHwidStr] = useState<string>('');

	// Return a string that is equal to the last two digits of the current year
	function getInitialYearStr(): string {
		let date = new Date();
		let year = date.getFullYear();
		return year.toString().slice(2);
	}

	// Return a string that is equal to the week number
	function getInitialWeekStr(): string {
		const currentDate = new Date();
		const startDate = new Date(currentDate.getFullYear(), 0, 1);
		const days = Math.floor((currentDate.getTime() - startDate.getTime()) / (24 * 60 * 60 * 1000));

		const weekNumber = Math.ceil(days / 7);
		return weekNumber.toString();
	}

	function getInitialStartDateStr(): string {
		let date = new Date();
		let month = date.toLocaleString('default', { month: 'short' }).toUpperCase();
		let year = date.getFullYear();
		return `${month} ${year}`;
	}

	async function updateHwidForm(control: RsFormControl<any>) {
		setHwidFormGroup(hwidFormGroup.clone().update(control));

		// We clone again because we are going to validate now and don't want to show errors yet
		const clonedHwidFormGroup = hwidFormGroup.cloneDeep();
		if (!(await clonedHwidFormGroup.isValid())) {
			setPreviewLabelStr('Complete form to see preview');
			setExampleHwidStr('');
			return;
		}

		const formData = clonedHwidFormGroup.toModel<HwidFormModel>();

		setPreviewLabelStr(
			`${formData.quantity} labels will be printed: ${labelService.getSerialNumberFromFormModel(
				formData,
				0
			)} -> ${labelService.getSerialNumberFromFormModel(formData, parseInt(formData.quantity) - 1)}`
		);
		setExampleHwidStr(labelService.getHwidStrFromFormModel(formData, 0));
	}

	function updateCageCodeForm(control: RsFormControl<any>) {
		setCageCodeFormGroup(cageCodeFormGroup.clone().update(control));
	}

	function updateGlobalParamForm(control: RsFormControl<any>) {
		setPrinterParamsFormGroup(printerParamsFormGroup.clone().update(control));
	}

	async function handlePrintHwidLabel() {
		if (!(await hwidFormGroup.isValid())) {
			rsToastify.error('Please fix HWID inputs.', 'Invalid HWID');
			setHwidFormGroup(hwidFormGroup.clone());
			return;
		}

		if (!(await printerParamsFormGroup.isValid())) {
			rsToastify.error('Please fix Printer Params inputs.', 'Invalid Printer Params');
			setPrinterParamsFormGroup(printerParamsFormGroup.clone());
			return;
		}

		const hwidFormModel = hwidFormGroup.toModel<HwidFormModel>();
		const printerParamsModel = printerParamsFormGroup.toModel<PrinterParamsModel>();
		const zplStr = labelService.generatePartLabelZpl(hwidFormModel, printerParamsModel);
		console.log(zplStr);
		const printWindow = window.open();
		if (!printWindow) {
			rsToastify.error('Failed to open print window.', 'Print Error');
			return;
		}
		printWindow.document.open('text/plain');
		printWindow.document.write(zplStr);
		printWindow.document.close();
		printWindow.focus();
		printWindow.print();
		printWindow.close();
	}

	async function handlePrintCageCodes() {
		if (!(await cageCodeFormGroup.isValid())) {
			rsToastify.error('Please fix Cage Code inputs.', 'Invalid Cage Code');
			setCageCodeFormGroup(cageCodeFormGroup.clone());
			return;
		}

		if (!(await printerParamsFormGroup.isValid())) {
			rsToastify.error('Please fix Printer Params inputs.', 'Invalid Printer Params');
			setPrinterParamsFormGroup(printerParamsFormGroup.clone());
			return;
		}

		const cageCodeFormModel = cageCodeFormGroup.toModel<CageCodeFormModel>();
		const printerParamsModel = printerParamsFormGroup.toModel<PrinterParamsModel>();
		const zplStr = labelService.generateCageLabelZpl(cageCodeFormModel, printerParamsModel);
		console.log(zplStr);
		const printWindow = window.open();
		if (!printWindow) {
			rsToastify.error('Failed to open print window.', 'Print Error');
			return;
		}
		printWindow.document.open('text/plain');
		printWindow.document.write(zplStr);
		printWindow.document.close();
		printWindow.focus();
		printWindow.print();
		printWindow.close();
	}

	return (
		<Page className={'rsLabelPage'}>
			<PageHeader title={'Labels'} />
			<Box className={'content'}>
				<Box
					display={'flex'}
					flexDirection={'column'}
					gap={16}
					borderBottom={`1px solid ${colors.neutralGrey500}`}
					paddingBottom={16}
					marginBottom={48}
				>
					<Box display={'flex'} justifyContent={'space-between'}>
						<Label variant={'subheader1'} weight={'medium'}>
							HWID Label
						</Label>
						<Box display={'flex'} gap={16}>
							<Button
								look={'outlinedPrimary'}
								onClick={() => setHwidFormGroup(hwidFormGroup.clone().resetToInitialValue())}
							>
								Reset
							</Button>
							<Button look={'containedPrimary'} onClick={handlePrintHwidLabel}>
								Print
							</Button>
						</Box>
					</Box>
					<Box className={'hardwareLabelLine1'}>
						<LabelInputText
							labelTitle={'Hardware Rev'}
							inputMode={'text'}
							placeholder={'5 characters max'}
							className={'hwRevInput'}
							control={hwidFormGroup.get(HwidFormKeys.HW_REV)}
							updateControl={updateHwidForm}
						/>
						<LabelSelect<{ label: string; value: string }>
							isCreatable
							className={'partNumberSelect'}
							labelTitle={'Part Number'}
							placeholder={'Select Part Number'}
							options={partSerialOptions}
							onCreateOption={(value) => {
								setPartSerialOptions((prevState) => {
									return [
										...prevState,
										{
											label: value,
											value: value
										}
									];
								});

								const updatedControl = hwidFormGroup.getCloneDeep(HwidFormKeys.PART_NUMBER);
								updatedControl.value = value;
								updateHwidForm(updatedControl).catch(console.error);
							}}
							control={hwidFormGroup.get(HwidFormKeys.PART_NUMBER)}
							updateControl={updateHwidForm}
						/>
						<LabelSelect<{ label: string; value: string }>
							isCreatable
							className={'locationSelect'}
							labelTitle={'Location'}
							placeholder={'Select'}
							options={locationOptions}
							onCreateOption={(value) => {
								setLocationOptions((prevState) => {
									return [
										...prevState,
										{
											label: value,
											value: value
										}
									];
								});

								const updatedControl = hwidFormGroup.getCloneDeep(HwidFormKeys.LOCATION);
								updatedControl.value = value;
								updateHwidForm(updatedControl).catch(console.error);
							}}
							control={hwidFormGroup.get(HwidFormKeys.LOCATION)}
							updateControl={updateHwidForm}
						/>
					</Box>
					<Box className={'hardwareLabelLine2'}>
						<LabelInputText
							labelTitle={'Prefix'}
							inputMode={'text'}
							placeholder={'X'}
							className={'prefixInput'}
							control={hwidFormGroup.get(HwidFormKeys.PREFIX)}
							updateControl={updateHwidForm}
						/>
						<LabelInputText
							labelTitle={'Starting Number'}
							inputMode={'text'}
							placeholder={'5 digits max including prefix'}
							className={'startNumberInput'}
							control={hwidFormGroup.get(HwidFormKeys.STARTING_NUMBER)}
							updateControl={updateHwidForm}
						/>
						<LabelInputText
							labelTitle={'Week'}
							inputMode={'text'}
							placeholder={'Week'}
							className={'weekInput'}
							control={hwidFormGroup.get(HwidFormKeys.WEEK)}
							updateControl={updateHwidForm}
						/>
						<LabelInputText
							labelTitle={'Year'}
							inputMode={'text'}
							placeholder={'X'}
							className={'yearInput'}
							control={hwidFormGroup.get(HwidFormKeys.YEAR)}
							updateControl={updateHwidForm}
						/>
						<Box width={1} minHeight={'100%'} bgColor={colors.neutralGrey500} />
						<LabelInputText
							labelTitle={'Quantity'}
							inputMode={'text'}
							placeholder={'Qty'}
							className={'qtyInput'}
							control={hwidFormGroup.get(HwidFormKeys.QUANTITY)}
							updateControl={updateHwidForm}
						/>
					</Box>
					<Label variant={'body1'} weight={'regular'}>
						{previewLabelStr}
					</Label>
					<Label variant={'body1'} weight={'regular'}>
						<span style={{ fontWeight: 700 }}>Example HWID: </span> {exampleHwidStr}
					</Label>
				</Box>
				<Box
					display={'flex'}
					flexDirection={'column'}
					gap={16}
					borderBottom={`1px solid ${colors.neutralGrey500}`}
					paddingBottom={16}
					marginBottom={48}
				>
					<Box display={'flex'} justifyContent={'space-between'}>
						<Label variant={'subheader1'} weight={'medium'}>
							Cage Code Labels
						</Label>
						<Box display={'flex'} gap={16}>
							<Button
								look={'outlinedPrimary'}
								onClick={() => setCageCodeFormGroup(cageCodeFormGroup.clone().resetToInitialValue())}
							>
								Reset
							</Button>
							<Button look={'containedPrimary'} onClick={handlePrintCageCodes}>
								Print
							</Button>
						</Box>
					</Box>
					<Box display={'flex'} gap={16} className={'cageCodeLine1'}>
						<LabelInputText
							disabled
							readOnly
							className={'cageCodeInput'}
							labelTitle={'Cage Code'}
							inputMode={'text'}
							placeholder={'(17V) 5JHA8'}
							control={cageCodeFormGroup.get(CageCodeFormKeys.CAGE_CODE)}
							updateControl={updateCageCodeForm}
						/>
						<LabelInputText
							className={'dateInput'}
							labelTitle={'Date Printed (Mon YYYY)'}
							inputMode={'text'}
							placeholder={'Dec 2023'}
							control={cageCodeFormGroup.get(CageCodeFormKeys.DATE)}
							updateControl={updateCageCodeForm}
						/>
						<Box width={1} minHeight={'100%'} bgColor={colors.neutralGrey500} />
						<LabelInputText
							className={'qtyInput'}
							labelTitle={'Quantity'}
							inputMode={'numeric'}
							placeholder={'Qty'}
							control={cageCodeFormGroup.get(CageCodeFormKeys.QUANTITY)}
							updateControl={updateCageCodeForm}
						/>
					</Box>
				</Box>
				<Box display={'flex'} flexDirection={'column'} gap={16} alignItems={'flex-start'}>
					<Label variant={'subheader1'} weight={'medium'}>
						Printer Parameters
					</Label>
					<Box display={'flex'} gap={16} className={'globalParamsLine1'} width={'100%'}>
						<Box width={'100%'}>
							<LabelInputText
								labelTitle={'Vertical Shift'}
								inputMode={'text'}
								placeholder={'0'}
								control={printerParamsFormGroup.get(PrinterParamsKeys.VERTICAL_SHIFT)}
								updateControl={updateGlobalParamForm}
							/>
							<Label variant={'caption1'} weight={'regular'}>
								Value must be between -120 (up) and 120 (down)
							</Label>
						</Box>
						<Box width={'100%'}>
							<LabelInputText
								labelTitle={'Horizontal Shift'}
								inputMode={'text'}
								placeholder={'0'}
								control={printerParamsFormGroup.get(PrinterParamsKeys.HORIZONTAL_SHIFT)}
								updateControl={updateGlobalParamForm}
							/>
							<Label variant={'caption1'} weight={'regular'}>
								Value must be between -600 (right) and 600 (left)
							</Label>
						</Box>
					</Box>
					<Box display={'flex'} gap={16} className={'globalParamsLine2'} width={'100%'}>
						<Box width={'100%'}>
							<LabelInputText
								labelTitle={'Darkness'}
								inputMode={'text'}
								placeholder={'0'}
								className={'labelShiftInput'}
								control={printerParamsFormGroup.get(PrinterParamsKeys.DARKNESS)}
								updateControl={updateGlobalParamForm}
							/>
							<Label variant={'caption1'} weight={'regular'}>
								Value must between 10 and 30
							</Label>
						</Box>
						<Box width={'100%'}>
							<LabelInputText
								labelTitle={'Print Speed'}
								inputMode={'text'}
								placeholder={'0'}
								className={'labelShiftInput'}
								control={printerParamsFormGroup.get(PrinterParamsKeys.SPEED)}
								updateControl={updateGlobalParamForm}
							/>
							<Label variant={'caption1'} weight={'regular'}>
								Value must be between 1 and 4
							</Label>
						</Box>
					</Box>
					<Checkbox
						labelText={'Double Horizontal'}
						look={'containedPrimary'}
						control={printerParamsFormGroup.get(PrinterParamsKeys.DOUBLE_HORIZONTAL)}
						updateControl={updateGlobalParamForm}
					/>
					<Box>
						<Label variant="body1" weight="regular" mb={4}>
							Label Size
						</Label>
						<RadioButtonGroup
							groupName="size"
							labelStyles={{ position: 'RIGHT', variant: 'body1', weight: 'regular' }}
							options={[
								{ label: '1 in X .5 in', value: 'NORMAL' },
								{ label: '.5 in X .5 in', value: 'SMALL' }
							]}
							control={printerParamsFormGroup.get(PrinterParamsKeys.SIZE)}
							updateControl={updateGlobalParamForm}
						/>
					</Box>
				</Box>
			</Box>
		</Page>
	);
};

export default LabelPage;
