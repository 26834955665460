import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
const tffPaths = [
	'/pdfStatic/fonts/icomoon.ttf',
	'/pdfStatic/fonts/Barlow-Regular.ttf',
	'/pdfStatic/fonts/Barlow-Bold.ttf'
];

/**
 * This function will generate a pdf with the screenshot of the element.
 * @param element
 * @param fileName
 */
export async function generatePdfWithScreenShot(element: HTMLElement, fileName: string) {
	const canvas = await html2canvas(element);
	const imgData = canvas.toDataURL('image/png');
	const pdf = new jsPDF('p', 'mm', 'a4');
	const width = pdf.internal.pageSize.getWidth();
	const height = pdf.internal.pageSize.getHeight();
	pdf.addImage(imgData, 'PNG', 0, 0, width, height);
	pdf.save(fileName);
}

export async function generatePdf(element: HTMLElement, fileName: string) {
	const pdf = new jsPDF('p', 'mm', 'a4');

	const fontsBase64 = await convertFontsToBase64(tffPaths);

	for (const [fontName, base64String] of Object.entries(fontsBase64)) {
		pdf.addFileToVFS(`${fontName}.ttf`, base64String);
		pdf.addFont(`${fontName}.ttf`, fontName, 'normal');
	}

	pdf.setFont('Barlow-Regular');

	let newElement = element.cloneNode(true) as HTMLElement;
	const pdfHeader = newElement.querySelector('.pdfHeader');
	const rsKitCheckoutTable = Array.from(newElement.querySelectorAll('.rsKitCheckoutTable'));
	const summaryTables = Array.from(newElement.querySelectorAll('.summaryTable'));

	let newElementHeader = document.createElement('div');
	newElementHeader.classList.add('rsKitCheckoutPdf');
	newElementHeader.appendChild(pdfHeader as HTMLElement);
	for (let i = 0; i < rsKitCheckoutTable.length; i++) {
		newElementHeader.appendChild(rsKitCheckoutTable[i] as HTMLElement);
	}
	await pdf.html(newElementHeader, {
		autoPaging: true,
		x: 0,
		y: 0,
		width: pdf.internal.pageSize.getWidth(),
		windowWidth: element.scrollWidth
	});

	for (let i = 0; i < summaryTables.length; i++) {
		pdf.addPage();
		let newElementTable = document.createElement('div');
		newElementTable.classList.add('rsKitCheckoutPdf');
		newElementTable.appendChild(summaryTables[i] as HTMLElement);
		await pdf.html(newElementTable, {
			autoPaging: true,
			x: 0,
			//@ts-ignore
			y: pdf.getPageHeight() * (pdf.getNumberOfPages() - 1),
			width: pdf.internal.pageSize.getWidth(),
			windowWidth: element.scrollWidth
		});
	}

	const blob = pdf.output('blob');
	const url = URL.createObjectURL(blob);
	const link = document.createElement('a');
	link.target = '_blank';
	link.href = url;
	link.download = fileName;
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
}

/**
 * Dont remove this function....
 * @param fontPaths
 * @returns
 */

// export async function generatePdf(element: HTMLElement, fileName: string) {
// 	const pdf = new jsPDF('p', 'mm', 'a4');

// 	const fontsBase64 = await convertFontsToBase64(tffPaths);

// 	for (const [fontName, base64String] of Object.entries(fontsBase64)) {
// 		pdf.addFileToVFS(`${fontName}.ttf`, base64String);
// 		pdf.addFont(`${fontName}.ttf`, fontName, 'normal');
// 	}

// 	pdf.setFont('Barlow-Regular');

// 	await pdf.html(element, {
// 		callback: function (pdf) {
// 			const blob = pdf.output('blob');
// 			const url = URL.createObjectURL(blob);
// 			const link = document.createElement('a');
// 			link.target = '_blank';
// 			link.href = url;
// 			link.download = fileName;
// 			document.body.appendChild(link);
// 			link.click();
// 			document.body.removeChild(link);
// 		},
// 		autoPaging: true,
// 		x: 0,
// 		y: 0,
// 		width: pdf.internal.pageSize.getWidth(),
// 		windowWidth: element.scrollWidth
// 	});
// }

async function convertFontsToBase64(fontPaths: string[]): Promise<Record<string, string>> {
	const fontPromises = fontPaths.map(async (fontPath) => {
		const response = await fetch(fontPath);
		const blob = await response.blob();
		return new Promise<string>((resolve, reject) => {
			const reader = new FileReader();
			reader.onloadend = () => {
				const base64String = (reader.result as string).split(',')[1];
				resolve(base64String);
			};
			reader.onerror = reject;
			reader.readAsDataURL(blob);
		});
	});

	const fontBase64Strings = await Promise.all(fontPromises);

	return fontPaths.reduce((acc, path, index) => {
		const fontName = path.split('/').pop()?.split('.')[0];
		if (fontName) {
			acc[fontName] = fontBase64Strings[index];
		}
		return acc;
	}, {} as Record<string, string>);
}
